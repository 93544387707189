.navbar {
    text-align: right;
    background-color: black !important;
}

.nav-link {
    color: white !important;
    font-size: calc(16px + (22 - 16) * ((100vw - 300px) / (1600 - 300)));
    margin: 0 calc(2px + (10 - 2) * ((100vw - 300px) / (1600 - 300)));
}
