#projects-div {
    padding-top: 50px;
    width: 85%;
    margin: auto;
}

#projects-heading {
    font-family: "PT Sans", Sans-Serif !important;
    font-size: 50px !important;
}

.card {
    margin: 30px;
    color: black;
    transition: transform 0.5s;
    padding: 30px 20px;
}

@media (max-width: 550px) {
    #projects {
        margin: 0;
    }
}

.card:hover {
    transform: scale(1.03);
}

@media (min-width: 991px) {
    .card {
        height: 310px;
    }
}

.card-title {
    font-family: "PT Sans", Sans-Serif;
    font-size: 40px;
    letter-spacing: 0.1rem;
    font-size: calc(
        18px + (40 - 18) * ((100vw - 300px) / (1600 - 300))
    ) !important;
}

.card-text {
    margin-top: 30px;
    font-family: "Cabin", Sans-Serif;
    font-size: 20px;
    font-weight: 600;
}

i {
    color: black;
    transition: transform 0.5s;
}

i:hover {
    transform: scale(1.1);
}

.chrome-icon {
    margin-left: 15px;
}

.first {
    background: linear-gradient(to right, #2980b9, #6dd5fa, #ffffff);
}

.second {
    background: linear-gradient(to right, #da413c, #e28c89, #ffffff);
}

.third {
    background-image: linear-gradient(
        109.9deg,
        rgba(251, 189, 100, 1) 1.8%,
        rgba(255, 207, 139, 1) 95.4%
    );
}

.fourth {
    background-image: linear-gradient(
        102.3deg,
        rgba(147, 39, 143, 1) 5.9%,
        rgba(234, 172, 232, 1) 64%,
        rgba(246, 219, 245, 1) 89%
    );
}

.fifth {
    background-image: linear-gradient(
        106.1deg,
        rgba(69, 242, 143, 0.52) 10.2%,
        rgba(14, 228, 175, 0.61) 83.6%
    );
}

.sixth {
    background-image: linear-gradient(
        105.3deg,
        rgb(71, 81, 155) 21.8%,
        rgb(127, 150, 207) 100.2%
    );
}

.seventh {
    background-image: linear-gradient(to top, #b64b66 0%, #ffb199 100%);
}

.eighth {
    background: -webkit-linear-gradient(
        to right,
        #56267e,
        #6f4eac
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #56267e,
        #6f4eac
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
